import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy, Row, Button, Bold } from "app/NativeComponents/common";
import { checkIfUserHasMetadata, formatPhoneNumber } from "app/NativeActions";

import { compareTwoStrings } from "string-similarity";
import CallButton from "./CallButton";

const phoneMatch = (property, phoneData) => {
  const { owner_name: owner } = property;
  const { cnam } = phoneData;
  const THRESHOLD = 0.8;
  if (!owner || !cnam) return { match: false };
  const o = owner.toLowerCase().replace(/[^a-z ]/gi, "");
  const c = cnam.toLowerCase().replace(/[^a-z ]/gi, "");
  const [o_s, c_s] = [o.split(" "), c.split(" ")];
  const sims = [];
  for (let i = 0; i < o_s.length; i++) {
    for (let j = 0; j < c_s.length; j++) {
      sims.push(compareTwoStrings(o_s[i], c_s[j]));
    }
  }
  const ownerMatch = Math.max(...sims) > THRESHOLD;

  if (phoneData.do_not_call) return { match: false, reason: "DNC List" };
  if (phoneData.is_litigator) return { match: false, reason: "Litigator" };
  if (phoneData.blocked) return { match: false, reason: "Blocked" };
  if (!ownerMatch) return { match: false };

  return { match: true };
};

class PhoneNumberColumnItem extends PureComponent {
  renderPhoneNumber() {
    const { property, section } = this.props;

    let phones = [];
    if (checkIfUserHasMetadata("people_data")) {
      phones = [
        ...property?.phone_numbers,
        ...property?.deal?.owner?.phone_numbers
      ];
    } else {
      phones = JSON.parse(JSON.stringify(property?.deal?.owner?.phone_numbers));
    }

    if (phones.length > 0 && Array.isArray(phones)) {
      const index = section.column_format_index;
      const ordered_phone_numbers = JSON.stringify(phones);
      const phoneData = JSON.parse(ordered_phone_numbers || [])[index] || {};
      const match = phoneMatch(property, phoneData);

      return (
        <Wrapper
          className={"deal-hover-preview"}
          style={{
            alignSelf: "stretch"
          }}
          tooltipPlacement={"top"}
        >
          <Row
            style={{
              whiteSpace: "nowrap"
            }}
          >
            {this.props.shouldPhoneNumberBeHighlighted(
              phoneData.phone_number
            ) ? (
              <Row>
                <Copy>
                  <Bold
                    style={{
                      backgroundColor: this.props.colors.active_color_muted
                    }}
                  >
                    {formatPhoneNumber(phoneData.phone_number)}
                  </Bold>
                </Copy>

                {phoneData?.do_not_call == 1 ? (
                  <Wrapper
                    noPress={true}
                    style={{
                      backgroundColor: this.props.colors.orange_color_muted,
                      borderRadius: 5,
                      padding: 0,
                      paddingRight: 5,
                      paddingLeft: 5,
                      marginLeft: 5
                    }}
                    tooltip={
                      "Please note: The displayed phone number is on the National Do Not Call Registry. Calling this number for unsolicited offers or promotions may violate telemarketing rules. Proceed at your own risk and ensure compliance with all relevant regulations."
                    }
                    tooltipPlacement="top"
                  >
                    <Row>
                      <Copy style={{ fontSize: 10 }}>DNC</Copy>
                    </Row>
                  </Wrapper>
                ) : null}
              </Row>
            ) : (
              <Row>
                <Copy>
                  {phoneData.phone_number
                    ? formatPhoneNumber(phoneData.phone_number)
                    : "--"}
                </Copy>
                {phoneData?.do_not_call == 1 ? (
                  <Wrapper
                    noPress={true}
                    style={{
                      backgroundColor: this.props.colors.orange_color_muted,
                      borderRadius: 5,
                      padding: 0,
                      paddingRight: 5,
                      paddingLeft: 5,
                      marginLeft: 5
                    }}
                    tooltip={
                      "Please note: The displayed phone number is on the National Do Not Call Registry. Calling this number for unsolicited offers or promotions may violate telemarketing rules. Proceed at your own risk and ensure compliance with all relevant regulations."
                    }
                    tooltipPlacement="top"
                  >
                    <Row>
                      <Copy style={{ fontSize: 10 }}>DNC</Copy>
                    </Row>
                  </Wrapper>
                ) : null}
              </Row>
            )}
          </Row>
          {phoneData.phone_number && (
            <Row>
              <>
                {!!phoneData.cnam ? (
                  <Copy
                    style={{
                      fontSize: 10,
                      marginRight: 5,
                      whiteSpace: "nowrap"
                    }}
                  >
                    {phoneData.cnam}
                  </Copy>
                ) : null}

                {!!phoneData.phone_label ? (
                  <Copy style={{ fontSize: 10, whiteSpace: "nowrap" }}>
                    {phoneData.phone_label}
                  </Copy>
                ) : null}
              </>
            </Row>
          )}
        </Wrapper>
      );
    }

    return <Copy>--</Copy>;
  }

  render() {
    const {
      property,
      colors,
      section,
      row_height,
      type,
      dark_mode,
      toggle_lead_images
    } = this.props;

    let phones = [];
    if (checkIfUserHasMetadata("people_data")) {
      phones = [...property?.phone_numbers, ...property?.deal?.phone_numbers];
    } else {
      phones = JSON.parse(JSON.stringify(property?.deal?.phone_numbers));
    }

    const index = section.column_format_index;
    const ordered_phone_numbers = JSON.stringify(phones);
    const phoneData = JSON.parse(ordered_phone_numbers || [])[index] || {};

    return (
      <Wrapper
        className={"deal-hover-preview"}
        style={{
          padding: 10,
          height: row_height,
          position: "relative"
        }}
      >
        <Wrapper
          style={{
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          {section.column_format == "single" ? (
            <Copy style={{ whiteSpace: "nowrap" }}>
              {phones.map((phone, i) => {
                if (i === 0) {
                  return phone.phone_number;
                }
                return ", " + phone.phone_number;
              })}
            </Copy>
          ) : (
            <Copy style={{ whiteSpace: "nowrap" }}>
              {this.renderPhoneNumber()}
            </Copy>
          )}

          {phoneData?.contact ? (
            <CallButton
              {...this.props}
              phone={phoneData}
              contact={phoneData?.contact}
              property={property}
            />
          ) : null}
        </Wrapper>
      </Wrapper>
    );
  }
}

export default PhoneNumberColumnItem;
