import React, { Component } from "react";
import { store } from "app/store";

import { Row } from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";
import LeadActionsButton from "./LeadActionsButton";
import DriveListButton from "./DriveListButton";
import AssignToCampaignButton from "./AssignToCampaignButton";
import DialerButton from "./DialerButton";
import { checkIfUserHasMetadata } from "app/NativeActions";

class ActionButtons extends Component {
  render() {
    const { active_list_tab, selected_leads, selected_all, filtered_count } =
      this.props;
    const is_list =
      active_list_tab?.id && (selected_leads.length == 0 || selected_all)
        ? true
        : false;

    const is_actionable =
      ((active_list_tab?.id && selected_leads.length == 0) ||
        selected_leads.length > 0 ||
        selected_all) &&
      active_list_tab?.building != 1
        ? true
        : false;
    const selected_all_leads =
      (active_list_tab?.id && selected_leads?.length == 0) || selected_all;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const isMobile = store.getState().native.isMobile;

    const phone_plan = checkIfUserHasMetadata("dialer");

    return (
      <>
        {!isMobile ? (
          <Row>
            {phone_plan &&
            (this.props.user?.team_owner == 1 ||
              this.props.user?.can_send_message == 1) ? (
              <DialerButton
                action_button={true}
                filtered_count={filtered_count}
                is_actionable={is_actionable}
                is_list={is_list}
                selected_leads={selected_leads}
                selected_all={selected_all_leads}
                clearAll={this.props.clearAll}
                applied_filter={this.props.applied_filter}
                search={this.props.search}
                active_list_tab={this.props.active_list_tab}
                lead_filters={this.props.lead_filters}
              />
            ) : null}

            {is_list && !phone_plan ? (
              <DriveListButton
                action_button={true}
                filtered_count={filtered_count}
                is_actionable={is_actionable}
                is_list={is_list}
                selected_leads={selected_leads}
                selected_all={selected_all_leads}
                clearAll={this.props.clearAll}
                applied_filter={this.props.applied_filter}
                search={this.props.search}
                active_list_tab={this.props.active_list_tab}
                lead_filters={this.props.lead_filters}
              />
            ) : null}

            <AssignToCampaignButton
              action_button={true}
              filtered_count={filtered_count}
              is_actionable={is_actionable}
              is_list={is_list}
              selected_leads={selected_leads}
              selected_all={selected_all_leads}
              clearAll={this.props.clearAll}
              applied_filter={this.props.applied_filter}
              search={this.props.search}
              active_list_tab={this.props.active_list_tab}
              lead_filters={this.props.lead_filters}
            />
            <LeadActionsButton {...this.props} />
          </Row>
        ) : (
          <LeadActionsButton {...this.props} />
        )}
      </>
    );
  }
}

export default ActionButtons;
