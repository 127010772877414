import React, { Component } from "react";
import { store } from "app/store";

import { Row, Scroll, Wrapper } from "app/NativeComponents/common";
import {
  GhostButton,
  InlineButton,
  PopoverMenu,
  SearchBar
} from "app/NativeComponents/snippets";

import AssignToCampaignButton from "./AssignToCampaignButton";
import AssignLeadButton from "./AssignLeadButton";
import DriveListButton from "./DriveListButton";
import SkipTraceButton from "./SkipTraceButton";
import ChangeStatusButton from "./ChangeStatusButton";
import CreateListButton from "./CreateListButton";
import AddToListButton from "./AddToListButton";
import RemoveFromListButton from "./RemoveFromListButton";
import RemoveFromThisListButton from "./RemoveFromThisListButton";
import AddTagsButton from "./AddTagsButton";
import RemoveTagsButton from "./RemoveTagsButton";
import PauseCampaignsButton from "./PauseCampaignsButton";
import EndCampaignsButton from "./EndCampaignsButton";
import ExportButton from "./ExportButton";
import DeleteButton from "./DeleteButton";

import { checkIfUserHasMetadata, numberWithCommas } from "app/NativeActions";
import DialerButton from "./DialerButton";

class LeadActionsButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      search: ""
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(show) {
    this.setState({
      show
    });
  }

  render() {
    const { show, search } = this.state;
    const {
      active_list_tab,
      selected_leads,
      selected_all,
      filtered_count,
      ghost_button = true,
      include_all_buttons = false,
      list_history_id = "",
      not_a_list = false
    } = this.props;
    const is_list =
      active_list_tab?.id &&
      (selected_leads?.length == 0 || selected_all) &&
      !not_a_list
        ? true
        : false;

    const is_actionable =
      (selected_leads?.length > 0 || selected_all) &&
      active_list_tab?.building != 1
        ? true
        : false;

    const selected_all_leads =
      (active_list_tab?.id && selected_leads?.length == 0) || selected_all;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const isMobile = store.getState().native.isMobile;
    const phone_plan = checkIfUserHasMetadata("dialer") && device !== "mobile";

    return (
      <>
        {is_actionable ? (
          <PopoverMenu
            show={show}
            no_swipe={true}
            no_cancel={true}
            onShow={s => {
              this.setState({
                show: s
              });
            }}
            includeCloseButton={true}
            popover_title={
              selected_all
                ? "Select an action for your " +
                  numberWithCommas(filtered_count) +
                  " selected leads."
                : "Select an action for your " +
                  numberWithCommas(selected_leads.length) +
                  " selected leads."
            }
            popover_width={350}
            popoverSheetTop={"75%"}
            renderComponent={({ hovering }) => {
              return (
                <Row
                  style={
                    !isMobile || device == "mobile"
                      ? ghost_button
                        ? { alignSelf: "stretch" }
                        : {}
                      : ghost_button
                      ? { alignSelf: "stretch", flexDirection: "column" }
                      : {}
                  }
                >
                  {ghost_button ? (
                    <GhostButton
                      style={
                        !isMobile
                          ? { marginRight: 0 }
                          : { alignSelf: "stretch" }
                      }
                      noPress={true}
                      pressedIn={show || hovering}
                      disabled={!is_actionable}
                      button_type={isMobile ? "full" : "normal"}
                      icon_right={isMobile ? "" : "keyboard-arrow-down"}
                      buttonContainerStyle={
                        device == "mobile" ? {} : { alignSelf: "stretch" }
                      }
                      primary={isMobile ? true : false}
                    >
                      {"Lead Actions"}
                    </GhostButton>
                  ) : (
                    <InlineButton
                      noPress={true}
                      pressedIn={show || hovering}
                      disabled={!is_actionable}
                      icon_right={isMobile ? "" : "keyboard-arrow-down"}
                    >
                      {"Lead Actions"}
                    </InlineButton>
                  )}
                </Row>
              );
            }}
            renderMenu={() => {
              return (
                <>
                  <Wrapper
                    style={{
                      alignSelf: "stretch"
                    }}
                  >
                    <SearchBar
                      title="Search Actions"
                      value={search}
                      onChange={value => {
                        this.setState({ search: value });
                      }}
                      style={{ margin: 10 }}
                    />
                  </Wrapper>
                  <Scroll
                    style={
                      device == "desktop"
                        ? {
                            maxHeight: 300,
                            alignSelf: "stretch"
                          }
                        : {
                            alignSelf: "stretch"
                          }
                    }
                  >
                    {is_list &&
                    (isMobile || include_all_buttons || phone_plan) ? (
                      <DriveListButton
                        button_search={search}
                        title={"Drive This List"}
                        filtered_count={filtered_count}
                        is_actionable={is_actionable}
                        is_list={is_list}
                        selected_leads={selected_leads}
                        selected_all={selected_all_leads}
                        clearAll={this.props.clearAll}
                        applied_filter={this.props.applied_filter}
                        search={this.props.search}
                        active_list_tab={this.props.active_list_tab}
                        toggleMenu={this.toggleMenu}
                        lead_filters={this.props.lead_filters}
                      />
                    ) : null}

                    {(isMobile || include_all_buttons) &&
                    phone_plan &&
                    (this.props.user?.team_owner == 1 ||
                      this.props.user?.can_send_message == 1) ? (
                      <DialerButton
                        filtered_count={filtered_count}
                        is_actionable={is_actionable}
                        is_list={is_list}
                        selected_leads={selected_leads}
                        selected_all={selected_all_leads}
                        clearAll={this.props.clearAll}
                        applied_filter={this.props.applied_filter}
                        search={this.props.search}
                        active_list_tab={this.props.active_list_tab}
                        lead_filters={this.props.lead_filters}
                      />
                    ) : null}

                    {isMobile || include_all_buttons ? (
                      <>
                        <AssignToCampaignButton
                          button_search={search}
                          title={"Start Mail"}
                          filtered_count={filtered_count}
                          is_actionable={is_actionable}
                          is_list={is_list}
                          selected_leads={selected_leads}
                          selected_all={selected_all_leads}
                          clearAll={this.props.clearAll}
                          applied_filter={this.props.applied_filter}
                          search={this.props.search}
                          active_list_tab={this.props.active_list_tab}
                          toggleMenu={this.toggleMenu}
                          list_history_id={list_history_id}
                          lead_filters={this.props.lead_filters}
                        />
                      </>
                    ) : null}

                    <AssignLeadButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"Assign Lead Owner"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />

                    <ChangeStatusButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"Change Status"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />
                    <CreateListButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"Create List"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />
                    <AddToListButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"Add To List(s)"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />
                    {!active_list_tab?.id && !not_a_list ? (
                      <RemoveFromListButton
                        button_search={search}
                        filtered_count={filtered_count}
                        title={"Remove From List(s)"}
                        selected_leads={selected_leads}
                        selected_all={selected_all_leads}
                        clearAll={this.props.clearAll}
                        toggleMenu={this.toggleMenu}
                        applied_filter={this.props.applied_filter}
                        search={this.props.search}
                        active_list_tab={this.props.active_list_tab}
                        lead_filters={this.props.lead_filters}
                      />
                    ) : (
                      <RemoveFromThisListButton
                        button_search={search}
                        list_id={active_list_tab?.id}
                        filtered_count={filtered_count}
                        title={"Remove From This List"}
                        selected_leads={selected_leads}
                        selected_all={selected_all_leads}
                        clearAll={this.props.clearAll}
                        toggleMenu={this.toggleMenu}
                        applied_filter={this.props.applied_filter}
                        search={this.props.search}
                        active_list_tab={this.props.active_list_tab}
                        list_history_id={list_history_id}
                        lead_filters={this.props.lead_filters}
                      />
                    )}

                    <AddTagsButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"Add Tag(s)"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />
                    <RemoveTagsButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"Remove Tag(s)"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />

                    <PauseCampaignsButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"Pause Mail"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />
                    <EndCampaignsButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"End Mail"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />

                    <ExportButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={is_list ? "Export Leads" : "Export Leads"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />

                    <DeleteButton
                      button_search={search}
                      filtered_count={filtered_count}
                      title={"Permanently Delete Leads"}
                      selected_leads={selected_leads}
                      selected_all={selected_all_leads}
                      clearAll={this.props.clearAll}
                      toggleMenu={this.toggleMenu}
                      applied_filter={this.props.applied_filter}
                      search={this.props.search}
                      active_list_tab={this.props.active_list_tab}
                      list_history_id={list_history_id}
                      lead_filters={this.props.lead_filters}
                    />
                  </Scroll>
                </>
              );
            }}
          />
        ) : (
          <GhostButton
            style={!isMobile ? { marginRight: 0 } : {}}
            noPress={true}
            disabled={!is_actionable}
            button_type={isMobile ? "full" : "normal"}
            icon_right={isMobile ? "" : "keyboard-arrow-down"}
          >
            {is_list ? "Lead Actions" : "Lead Actions"}
          </GhostButton>
        )}
      </>
    );
  }
}

export default LeadActionsButtons;
