import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  ModalContainer,
  Wrapper,
  Scroll,
  Row,
  Card,
  CardBody,
  Title,
  Icon,
  Copy,
  Bold,
  KeyboardView
} from "app/NativeComponents/common";
import {
  InputBox,
  SearchBar,
  AttentionBox
} from "app/NativeComponents/snippets";

import {
  changeTab,
  tabNavigation,
  addListToListTabs,
  editBulkUploadInfo,
  setUploadListHeaders,
  popAllSidePanels,
  popSidePanel,
  checkIfUserHasMetadata,
  addProgressBar
} from "app/NativeActions";

import MappingObject from "./MappingObject";
import moment from "moment";

const IGNORE_VALUE = "IGNORE";

class UploadMapping extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getMappingState();
  }

  getMappingState() {
    let header_edited_map = [];
    let formatted_custom_fields = [];

    if (this.props.upload_list_info) {
      if (this.props.upload_list_info.header) {
        for (let i = 0; i < this.props.upload_list_info.header.length; i++) {
          header_edited_map.push({
            header_title: this.props.upload_list_info.header[i],
            label: this.props.upload_list_info.header[i],
            header_index: i,
            header_mapped_value: "",
            value: ""
          });
        }
      }
      if (this.props.upload_list_info?.custom_fields) {
        for (
          let i = 0;
          i < this.props.upload_list_info.custom_fields.length;
          i++
        ) {
          formatted_custom_fields.push({
            label: this.props.upload_list_info.custom_fields[i].title,
            value: this.props.upload_list_info.custom_fields[i].slug
          });
        }
      }
    }

    return {
      new_list_name: !!this.props.upload_list_info?.list_title
        ? this.props.upload_list_info?.list_title
        : "Imported List",
      search: "",
      loading: false,
      header_edited_map: header_edited_map,
      header_options: [
        {
          label: "N/A",
          value: ""
        },
        {
          label: "Skip Column",
          value: IGNORE_VALUE
        },
        {
          label: "Full Address",
          value: "FULL_ADDRESS"
        },
        {
          label: "Address Line 1",
          value: "ADDRESS"
        },
        {
          label: "Address Line 2",
          value: "ADDRESS_2"
        },
        {
          label: "Address Number",
          value: "ADDRESS_RANGE"
        },
        {
          label: "Address Street",
          value: "ADDRESS_STREET"
        },
        {
          label: "City",
          value: "CITY"
        },
        {
          label: "State",
          value: "STATE"
        },
        {
          label: "Zipcode",
          value: "ZIP"
        },
        { label: "County", value: "COUNTY" },
        {
          label: "Parcel Number",
          value: "PARCEL_NUMBER"
        },
        { label: "Property ID", value: "PROPERTY_ID" },
        {
          label: "Latitude",
          value: "LATITUDE"
        },
        {
          label: "Longitude",
          value: "LONGITUDE"
        },
        ...formatted_custom_fields
      ]
    };
  }

  componentDidMount() {}

  checkIfAllPropertyAddressFieldsAreSelected() {
    let address_check = false;
    let address_street_check = false;
    let address_range_check = false;

    let city_check = false;
    let state_check = false;
    let zip_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "FULL_ADDRESS":
          return true;

        case "ADDRESS":
          address_check = true;
          break;

        case "ADDRESS_RANGE":
          address_range_check = true;
          break;

        case "ADDRESS_STREET":
          address_street_check = true;
          break;

        case "CITY":
          city_check = true;
          break;

        case "STATE":
          state_check = true;
          break;

        case "ZIP":
          zip_check = true;
          break;

        default:
          break;
      }
    }

    if (address_street_check && address_range_check) {
      address_check = true;
    }

    if (address_check && city_check && state_check && zip_check) {
      return true;
    }

    return false;
  }

  checkIfAllPropertyParcelFieldsAreSelected() {
    let parcel_check = false;
    let county_check = false;
    let state_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "PARCEL_NUMBER":
          parcel_check = true;
          break;

        case "STATE":
          state_check = true;
          break;
        case "COUNTY":
          county_check = true;
          break;

        default:
          break;
      }
    }

    if (parcel_check && county_check && state_check) {

      return true;
    }

    return false;
  }

  checkIfPropertyIdFieldIsSelected() {
    let property_id_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "PROPERTY_ID":
          property_id_check = true;
          break;

        default:
          break;
      }
    }

    if (property_id_check) {

      return true;
    }

    return false;
  }

  checkIfAllLatLngFieldsAreSelected() {
    let lat_check = false;
    let lng_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "LATITUDE":
          lat_check = true;
          break;

        case "LONGITUDE":
          lng_check = true;
          break;

        default:
          break;
      }
    }

    if (lat_check && lng_check) {
      return true;
    }

    return false;
  }

  setHeaderOption(header_index, value) {
    this.setState({
      header_edited_map: this.state.header_edited_map.map((header, i) => {
        if (i === header_index) {
          return {
            ...header,
            value,
            header_mapped_value: value
          };
        }
        if (
          value === header.header_mapped_value &&
          value !== "" &&
          value !== IGNORE_VALUE &&
          (header.header_mapped_value === "FULL_ADDRESS" ||
            header.header_mapped_value === "ADDRESS" ||
            header.header_mapped_value === "ADDRESS_2" ||
            header.header_mapped_value === "ADDRESS_RANGE" ||
            header.header_mapped_value === "ADDRESS_STREET" ||
            header.header_mapped_value === "CITY" ||
            header.header_mapped_value === "STATE" ||
            header.header_mapped_value === "ZIP")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }

        if (
          value === "FULL_ADDRESS" &&
          (header.header_mapped_value === "FULL_ADDRESS" ||
            header.header_mapped_value === "ADDRESS" ||
            header.header_mapped_value === "ADDRESS_2" ||
            header.header_mapped_value === "ADDRESS_RANGE" ||
            header.header_mapped_value === "ADDRESS_STREET" ||
            header.header_mapped_value === "CITY" ||
            header.header_mapped_value === "STATE" ||
            header.header_mapped_value === "ZIP")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }

        if (
          value === "ADDRESS" &&
          (header.header_mapped_value === "ADDRESS_RANGE" ||
            header.header_mapped_value === "ADDRESS_STREET")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }
        if (
          value === "PARCEL_NUMBER" &&
          (header.header_mapped_value === "PARCEL_NUMBER" ||
            header.header_mapped_value === "COUNTY")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }
        if (
          value === "PROPERTY_ID" &&
          header.header_mapped_value === "PROPERTY_ID"
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }
        if (
          header.header_mapped_value === "LONGITUDE" &&
          header.header_mapped_value === "LATITUDE"
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }

        if (
          header.header_mapped_value === "ADDRESS" &&
          (value === "ADDRESS_RANGE" || value === "ADDRESS_STREET")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }

        return header;
      })
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.upload_list_info &&
      this.props.upload_list_info !== prevProps.upload_list_info
    ) {
      this.setState(this.getMappingState());
    }

    if (prevState.header_edited_map !== this.state.header_edited_map) {
      if (
        (this.checkIfAllPropertyAddressFieldsAreSelected() ||
          this.checkIfAllPropertyParcelFieldsAreSelected() ||
          this.checkIfPropertyIdFieldIsSelected() ||
          this.checkIfAllLatLngFieldsAreSelected()) &&
        !this.state.address_fields_are_selected
      ) {
        this.setState({ address_fields_are_selected: true });

        this.setState({
          header_edited_map: this.state.header_edited_map.map((header, i) => {
            if (
              header.header_mapped_value === "" ||
              header.header_mapped_value === "N/A" ||
              header.header_mapped_value === null
            ) {
              return {
                ...header,
                header_mapped_value: IGNORE_VALUE
              };
            }
            return header;
          })
        });
      } else if (
        (!this.checkIfAllPropertyAddressFieldsAreSelected() ||
          !this.checkIfAllPropertyParcelFieldsAreSelected() ||
          !this.checkIfPropertyIdFieldIsSelected() ||
          this.checkIfAllLatLngFieldsAreSelected()) &&
        this.state.address_fields_are_selected
      ) {
        this.setState({ address_fields_are_selected: false });
      }
    }
  }

  submitList() {
    let mapping = [];
    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      if (this.state.header_edited_map[i].header_mapped_value !== "") {
        mapping.push(this.state.header_edited_map[i].header_mapped_value);
      } else {
        mapping.push(IGNORE_VALUE);
      }
    }

    this.props.setUploadListHeaders({
      token: this.props.token,
      bulk_job_id: this.props.upload_list_info.id,
      new_list_name: this.state.new_list_name,
      mapping: JSON.stringify(mapping),
      options: JSON.stringify({
        new_list_name: this.state.new_list_name
      }),
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });

        this.props.popSidePanel();

        //make this uploaded list the active one
        if (results.list && results.bulk_job_id) {
          this.props.addProgressBar({
            id: results?.bulk_job_id,
            type: "import",
            progress: results.completed_count,
            completed: results.completed,
            total_count: results.total_count,
            data: {
              list: results.list
            }
          });
        }
      }
    });
  }

  render() {
    return (
      <>
        <InputBox
          input_ref={this._filter_name}
          autoFocus={false}
          name="filter_name"
          disabled={false}
          returnKeyType="done"
          placeholder={
            !!this.state.new_list_name
              ? "Imported list name"
              : "Enter a name for this imported list"
          }
          onFocus={() => {}}
          onChange={value => {
            this.setState({
              new_list_name: value
            });
          }}
          blurOnSubmit={true}
          value={this.state.new_list_name}
          input_type="text"
          require_confirm={true}
          has_changed={
            (this.checkIfAllPropertyAddressFieldsAreSelected() ||
              this.checkIfAllPropertyParcelFieldsAreSelected() ||
              this.checkIfPropertyIdFieldIsSelected() ||
              this.checkIfAllLatLngFieldsAreSelected()) &&
            !!this.state.new_list_name
          }
          submit_button_primary={true}
          submit_button_title={"Complete Import"}
          onSubmit={() => this.submitList()}
          loading={this.state.loading}
        />

        <SearchBar
          title="Search Columns"
          value={this.state.search}
          onChange={value => {
            this.setState({
              search: value
            });
          }}
          style={{ margin: 10 }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {!this.checkIfAllPropertyAddressFieldsAreSelected() ||
          !this.checkIfAllPropertyParcelFieldsAreSelected() ||
          !this.checkIfPropertyIdFieldIsSelected() ||
          !this.checkIfAllLatLngFieldsAreSelected() ? (
            <AttentionBox
              primary={true}
              title={"Map your spreadsheet to DealMachine."}
              description={
                "Once you submit your list, we'll look up the property owners and add them to your account. We'll send you an email once your import is complete. At least one field (or a combination) must map to a “Full Address” to complete the import."
              }
            />
          ) : null}
          {this.state.header_edited_map.map((header, i) => {
            if (
              header.header_title
                .toLowerCase()
                .includes(this.state.search.toLowerCase())
            ) {
              return (
                <MappingObject
                  key={"upload_" + i}
                  header_item={header}
                  header_index={i}
                  header_options={this.state.header_options}
                  setHeaderOption={this.setHeaderOption.bind(this)}
                  upload_list_info={this.props.upload_list_info}
                  colors={this.props.colors}
                />
              );
            }
            return null;
          })}
        </KeyboardView>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { dark_mode, colors } = settings;

  return {
    token,
    user,
    dark_mode,
    colors
  };
};

export default connect(mapStateToProps, {
  changeTab,
  tabNavigation,
  editBulkUploadInfo,
  setUploadListHeaders,
  popAllSidePanels,
  popSidePanel,
  addListToListTabs,
  addProgressBar
})(UploadMapping);
